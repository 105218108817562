
import { computed, defineComponent } from "vue";
import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    AppLink
  },
  setup() {
    const store = useStore();
    const isHeaderFixed = computed(
      () => store.getters["pricingHeader/isHeaderFixed"]
    );
    const isHeaderHidden = computed(
      () => store.getters["pricingHeader/isHeaderHidden"]
    );
    const headerClass = computed(() =>
      isHeaderHidden.value ? "hidden" : isHeaderFixed.value ? "show" : "hidden"
    );
    return {
      isHeaderFixed,
      headerClass
    };
  }
});
