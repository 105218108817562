
import { defineComponent } from "vue";
import CheckIconBlue from "@/components/atoms/icon/CheckIconBlue/CheckIconBlue.vue";
import PricingEtc from "@/components/atoms/icon/PricingEtc/PricingEtc.vue";
import PricingTime from "@/components/atoms/icon/PricingTime/PricingTime.vue";
import PricingService from "@/components/atoms/icon/PricingService/PricingService.vue";
import MinusIcon from "../../atoms/icon/MinusIcon/MinusIcon.vue";
import PricingHeader from "@/components/molecules/PricingHeader/PricingHeader.vue";
export default defineComponent({
  components: {
    CheckIconBlue,
    PricingEtc,
    PricingTime,
    PricingService,
    MinusIcon,
    PricingHeader
  }
});
