<template>
  <svg
    width="200"
    height="27"
    viewBox="0 0 200 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M96.5938 16.086V14.61H103.434V16.086H96.5938Z" fill="#333333" />
  </svg>
</template>
