
import { defineComponent, onMounted } from "vue";
import { trackerSetAction, trackerSetClick } from "@/utils/vertebraSdk";
import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";
import { data } from "@/shared/pricing";
// import AppLink from "@/components/atoms/link/AppLink/AppLink.vue";
import PricingIcon from "@/components/atoms/icon/PricingIcon/PricingIcon.vue";
import SubIntroContent from "@/components/templates/SubIntroContent/SubIntroContent.vue";
// import GuideFaq from "@/components/templates/GuideFaq/GuideFaq.vue";
// import SiteShortcut from "@/components/templates/SiteShortcut/SiteShortcut.vue";
import store from "@/store";
import PriceTable from "@/components/templates/PriceTable/PriceTable.vue";
// import PricingDetail from "@/components/templates/PricingDetail/PricingDetail.vue";
// import SubBannerSignup from "@/components/templates/SubBannerSignup/SubBannerSignup.vue";
// import PricingTime from "../../components/atoms/icon/PricingTime/PricingTime.vue";
// import PricingService from "../../components/atoms/icon/PricingService/PricingService.vue";
// import PricingEtc from "../../components/atoms/icon/PricingEtc/PricingEtc.vue";

export default defineComponent({
  components: {
    // AppLink,
    PricingIcon,
    SubIntroContent,
    // GuideFaq,
    // SiteShortcut,
    PriceTable
  },
  setup() {
    useHead({
      title: data.sitemapTitle,
      link: [
        { rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}/pricing` }
      ]
    });
    const trackEventConsultingPricing = () => {
      gtagEvents("요금제 문의하기");
      trackerSetClick("ask-pricing");
    };

    const initObserver = (
      target: Element,
      callback: (entries: IntersectionObserverEntry[]) => void,
      option: IntersectionObserverInit
    ) => {
      const iob = new IntersectionObserver(entries => {
        callback(entries);
      }, option);
      iob.observe(target);
    };

    const { caption, title, description, payment, faq } = data;

    onMounted(() => {
      trackerSetAction("요금제 페이지");
      // PriceTable 타겟
      const priceSectionRef = document.querySelector(".section-price");
      const appLinkRef = document.querySelector("#pricing-button");
      if (priceSectionRef && appLinkRef) {
        initObserver(
          appLinkRef,
          entries => {
            store.dispatch(
              "pricingHeader/setHidden",
              entries[0].isIntersecting
            );
          },
          {
            threshold: 0
          }
        );
        initObserver(
          priceSectionRef,
          entries => {
            store.dispatch("pricingHeader/setFixed", entries[0].isIntersecting);
          },
          {
            threshold: 0
          }
        );
      }
    });
    return {
      payment,
      caption,
      title,
      description,
      faq,
      trackEventConsultingPricing
    };
  }
});
