<template>
  <svg
    width="335"
    height="102"
    viewBox="0 0 335 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M170 4L170 96"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M147 19L147 35"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M139 27L155 27"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M185 27L201 27"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M185 70H201"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M185 78H201"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M152.656 67.3433L141.343 78.657"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M141.344 67.3433L152.657 78.657"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M124 50L216 50"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M143.302 4C138.458 4 134.774 4 131.994 4C127.576 4 124 7.58172 124 12L124 88C124 92.4183 127.582 96 132 96L208 96C212.418 96 216 92.4183 216 88L216 12C216 7.58172 212.418 4 208 4L153.723 4"
      stroke="#333333"
      stroke-width="1.8"
      stroke-linecap="round"
    />
    <path
      d="M236 96L320.56 96"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M250.615 25.011L250.615 96"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M270.451 39.6265L270.451 96.0001"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M310.121 68.8572L310.121 96"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M290.285 54.2417L290.285 95.9999"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M314.453 40.8279L333.244 59.6191"
      stroke="#333333"
      stroke-width="1.8"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M297.594 46.9341C310.278 46.9341 320.561 36.6514 320.561 23.9671C320.561 11.2827 310.278 1 297.594 1C284.91 1 274.627 11.2827 274.627 23.9671C274.627 36.6514 284.91 46.9341 297.594 46.9341Z"
      fill="white"
      stroke="#333333"
      stroke-width="1.8"
    />
    <path
      d="M298 34C303.523 34 308 29.5229 308 24C308 18.4772 303.523 14 298 14C292.477 14 288 18.4772 288 24C288 29.5229 292.477 34 298 34Z"
      fill="#4384FF"
    />
    <path
      d="M69.617 97.4191C63.863 99.729 57.5799 101 51 101C23.3858 101 1 78.6142 1 51C1 23.3858 23.3858 1 51 1C78.6143 1 101 23.3858 101 51C101 61.337 97.8631 70.9414 92.4894 78.9131"
      stroke="#333333"
      stroke-width="1.8"
      stroke-linecap="round"
    />
    <path
      d="M50.9997 88.234C71.5635 88.234 88.2337 71.5637 88.2337 50.9999C88.2337 30.4361 71.5635 13.7659 50.9997 13.7659C30.4359 13.7659 13.7656 30.4361 13.7656 50.9999C13.7656 71.5637 30.4359 88.234 50.9997 88.234Z"
      fill="#FED800"
      stroke="#333333"
      stroke-width="1.8"
      stroke-linecap="round"
    />
    <path
      d="M68.9232 38.7358L60.0841 68.9245L51.9679 38.7358L43.1287 68.9245L34.9609 38.7358"
      stroke="black"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.4219 54.2346H70.5171"
      stroke="black"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
